import React from "react";
import OnboardingSetPassword from "../../components/cards/onboardingCard/setPassword";
import "./style.scss";
import { LiveChatWidget } from '@livechat/widget-react'

const SetPassword = (token) => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
        <OnboardingSetPassword 
        token={token}
        />
      </div>
    </div>
  );
};

export default SetPassword;
