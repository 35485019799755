import React from "react";
import "./style.scss";

const Input = ({ placeholder, icon, ...props }) => {
  return (
    <input
      type="text"
      className="form-control ip-no-icon"
      placeholder={placeholder}
      {...props}
    />
  );
};

export default Input;
