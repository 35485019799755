import React, { Fragment, useEffect, useState } from "react";
import Title from "../../../text/title";
import LinkText from "../../../text/linkText";
import Toaster from "../../../common/Toaster";
import { Formik } from "formik";
import RoundedBtn from "../../../button/roundedBtn";
import "./style.scss";
import OptionCard from "../../optionCard";
import Input from "../../../input";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
// import { addNewBrand as addBrand } from "../../../../actions/brand/action";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";
import InputWithIcon from "../../../input/inputWithIcon";
import { setPasswordHandler } from "../../../../actions/user/action";

const OnboardingSetPassword = ({ token,setPasswordHandler, brandId, zipcode, statecode }) => {
  const [isMobile, setMobile] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [toastrType, setToastrType] = useState("success");
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
    
  }, []);


  return (
    <Fragment>
      {/* <div className="whole-page-loader">
        <img src="/images/common/loader.svg" />
      </div> */}
      <Toaster refresh={refresh} type={toastrType} message={toastrMsg} />
      <div className="onboarding row set-password">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center margin-top-img lottie">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
            <div className="lottie-subtext">
              <span>Hi , </span> <br />
              let's set your password. <br />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
        <Formik
            initialValues={{password:'',confirmPassword:''}}
            enableReinitialze= {true}
            validate={(values) => {
              const errors = {};

              if (!values.password) {
                errors.password = "Required";
              } else if (
                
                // !(/[A-Z]/.test(values.password) && /[0-9]/.test(values.password) &&  /^[!@#\$%\^&\*A-Za-z0-9]{8,25}$/.test(values.password))
                // !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i.test(values.password)
                !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,35}$/i.test(values.password)
              ) {
                errors.password = "Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = "Required";
              }
              else if(values.password !== values.confirmPassword){
                errors.confirmPassword = "Password and confirm password do not match"
              }
              
              return errors;
            }}
            onSubmit={async (values) => {
              // console.log(values)
              if(!loading){
                setLoading(true)
                var setUserPassword = await setPasswordHandler({...values,token:token.token});
                if (setUserPassword && !setUserPassword.error) {
                  
                  setToastrMsg("New Password Created");
                  setToastrType('success')
                  doRefresh((prev) => prev + 1);
                  setLoading(false)
                  setTimeout(() => {
                    navigate("/login")
                  }, 2*1000);

                 
                } else {
                  setToastrMsg(setUserPassword.errorData);
                  setToastrType('error')
                  doRefresh((prev) => prev + 1);
                  setLoading(false)
                }
              }
              
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className="form-register">
                <>
                  <div className="form">
                    <div className="ip-group">
                      <InputWithIcon
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        icon="/images/common/password-lock-icon.svg"
                      />
                      {errors.password && touched.password && (
                        <p className="error-class">{errors.password}</p>
                      )}
                    </div>
                    <div className="ip-group">
                      <InputWithIcon
                        placeholder={"Confirm Password"}
                        icon="/images/common/password-lock-icon.svg"
                        type="password"
                        name="confirmPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                      />
                      {errors.confirmPassword && touched.confirmPassword && (
                        <p className="error-class">{errors.confirmPassword}</p>
                      )}
                    </div>
                   
                  </div>
                  <div className="row back-buttons">
                  
                    <div className="d-flex nav-icons">
                    <div className="nav-conatiner">
                    
                    <button 
                    onClick={() => handleSubmit()}
                    >Continue</button>
                    
                  </div>
                    </div>

                    {/* <div className="col-md-6 col-lg-6 col-sm-6 col-12"> </div>
                    <div className="col-md-6 col-lg-6 col-sm-6 col-12 step3-btn">
                      <RoundedBtn
                        background="#31FFEA"
                        color="#004E93"
                        tag="Continue"
                        type="submit"
                      />
                    </div> */}
                  </div>
                </>
              </form>
            )}
            </Formik>
         
        </div>
        {/* <div className={!isMobile ? "row" : ""}>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 ">
            <div className="nav-conatiner">
              <div>
              <button>Continue</button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ brand }) => ({
  statecode: brand.statecode,
  zipcode: brand.zipcode,
  brandId: brand.brandId,
});

export default connect(mapStateToProps, { setPasswordHandler })(OnboardingSetPassword);
