import React from "react";
import "./style.scss";

const OptionCard = ({ name, type = "small", ...props }) => {
  return (
    <div
      className={`option-card ${
        type === "medium" ? "option-med" : "option-small"
      }`}
      {...props}
    >
      {name}
    </div>
  );
};

export default OptionCard;
